@font-face {
    font-family: 'AG';
    src: url('./type/AkzidGrtskNext-Med.woff2') format('woff2'),
        url('./type/AkzidGrtskNext-Med.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AG';
    src: url('./type/AkzidGrtskNext-LightItalic.woff2') format('woff2'),
        url('./type/AkzidGrtskNext-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'AG';
    src: url('./type/AkzidGrtskNext-Bold.woff2') format('woff2'),
        url('./type/AkzidGrtskNext-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AG';
    src: url('./type/AkzidGrtskNext-Regular.woff2') format('woff2'),
        url('./type/AkzidGrtskNext-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AG';
    src: url('./type/AkzidGrtskNext-Light.woff2') format('woff2'),
        url('./type/AkzidGrtskNext-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ogg';
    src: url('./type/Ogg-Roman.woff2') format('woff2'),
        url('./type/Ogg-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ogg';
    src: url('./type/Ogg-Italic.woff2') format('woff2'),
        url('./type/Ogg-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TN';
    src: url('./type/TimesNow-SemiLight.woff2') format('woff2'),
        url('./type/TimesNow-SemiLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
  margin: 0;
  font-family: 'AG', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: rgb(40,40,40);
  /* background: #f9f1f1; */
  background: #f8e4b5;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(242,17,17);
}

.stuck {
  overflow: hidden;
  /* pointer-events: none; */
}

a {
  text-decoration: none;
  color: rgb(242,17,17);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
